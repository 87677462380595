import { useMemo } from "react";
const useReviewsRoutableTypes = () => {
  return useMemo(
    () => ({
      // ...yourPackage,
    }),
    [
      // yourPackage,
    ]
  );
};
export {
  useReviewsRoutableTypes
};
